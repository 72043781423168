import { css, cx } from "emotion";
import { getAttributes } from "../../brite-viewer/utils";

export const Text = ({ item, totalColumns, containerStyle }) => {
  // Intention is to replace the hyperlink with the desired url.
  if (item?.content?.includes("href")) {
    // Use a container to store the html content to access it.
    const contentContainer = document.createElement("div");
    contentContainer.innerHTML = item.content;

    // Find the hyperlink url.
    const hyperlinkUrl = contentContainer?.querySelector("a")?.href;

    // Find the hyperlink text element and convert it to string.
    const hyperlinkText = contentContainer?.querySelector("u")?.outerHTML;

    // Replace the hyperlink text with the url.
    item.content = item.content.replace(hyperlinkText, `\n${hyperlinkUrl}`);
  }

  const componentAttributes = getAttributes({
    column: item,
    length: totalColumns,
    type: "component",
  });

  // V1 Styles (remove once textV1 is deprecated)
  const textComponentClass = cx(
    componentAttributes.className,
    css`
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      line-height: 1.2em;
      p {
        display: inline-block;
        line-height: 1.2em;
        width: 100%;
        margin: 0;
        padding: 0;
      }
      ol,
      ul {
        margin: 0;
        line-height: 0;
      }
      li {
        margin: 0;
        line-height: 1.5em;
        white-space: pre-line;
      }
    `
  );

  // V2 Styles
  const textV2ComponentClass = cx(
    componentAttributes.className,
    css`
      ol,
      ul,
      p {
        margin: 0;
        li {
          margin: 0;
        }
      }
    `
  );

  return (
    <div style={containerStyle}>
      <pre
        {...componentAttributes}
        className={
          item?.component === "textV2"
            ? textV2ComponentClass
            : textComponentClass
        }
        dangerouslySetInnerHTML={{ __html: item?.content || "" }}
      ></pre>
    </div>
  );
};
