import { PDFQRText } from "../PDFQRText";

export const Website = ({ item }) => {
  return (
    <PDFQRText
      title={item?.content}
      description={item?.action?.data?.url}
      url={item?.action?.data?.url}
    />
  );
};
