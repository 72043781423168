import { format } from "date-fns";
import { get } from "lodash";
import {
  bignumber,
  divide,
  multiply,
  subtract,
  format as mathJSFormat,
} from "mathjs";

export const formatCurrency = (value, decimals = 2) => {
  try {
    const formatValue = value.toLocaleString("en-US", {
      style: "decimal",
      maximumFractionDigits: 2,
      minimumFractionDigits: decimals,
    });

    return formatValue === "NaN" ? "0" : formatValue;
  } catch {
    return "0";
  }
};

export const getDisplayNumberValue = (value, options = {}) => {
  const { decimals } = options;
  let next;
  try {
    next = typeof value === "string" ? value.replace(/[^0-9.]/g, "") : value;
  } catch {
    next = decimals === 2 ? "0.00" : "0";
  }
  return formatCurrency(parseFloat(next || "0"), decimals);
};

export const displayFormatMap = {
  date: (value) => {
    try {
      const [day] = value.split("T");
      const date = day.replace(/-/g, "/");
      const nextDate = new Date(date);
      return format(nextDate, "yyyy-MM-dd");
    } catch (err) {
      console.warn(err);
      return "";
    }
  },
  dollar: getDisplayNumberValue,
  percent: getDisplayNumberValue,
  advancedInputField: getDisplayNumberValue
};

const currencyFormatterCents = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
});

export const getProductContributions = ({ cost, key, interval = 12 }) => {
  const totalMonthlyPremiums = cost?.TotalMonthlyPremiums?.[key];
  const contribution = cost?.Contributions?.MonthlyContributions?.[key];
  const contributionType = cost?.Contributions?.ContributionType;

  const data = bignumber(Number(totalMonthlyPremiums || 0));
  let value = multiply(data, bignumber(12));

  const employerData = bignumber(Number(contribution) || 0);
  const formatType = contributionType || "$";
  let employerValue = employerData;

  if (formatType === "%") {
    employerValue = divide(employerData, bignumber(100));
    employerValue = multiply(employerValue, value);
  } else if (formatType === "$") {
    employerValue = multiply(employerValue, bignumber(12));
  }

  let intervalValue = divide(employerValue, bignumber(interval));

  return currencyFormatterCents.format(
    mathJSFormat(intervalValue, { precision: 13 })
  );
};

export const getProductPremium = ({ cost, key, interval = 12 }) => {
  const totalMonthlyPremiums = cost?.TotalMonthlyPremiums?.[key];
  const contribution = cost?.Contributions?.MonthlyContributions?.[key];
  const contributionType = cost?.Contributions?.ContributionType;

  const data = bignumber(Number(totalMonthlyPremiums || 0));
  let value = multiply(data, bignumber(12));

  const employerData = bignumber(Number(contribution) || 0);
  const formatType = contributionType || "$";
  let employerValue = employerData;

  if (formatType === "%") {
    employerValue = divide(employerData, bignumber(100));
    employerValue = multiply(employerValue, value);
  } else if (formatType === "$") {
    employerValue = multiply(employerValue, bignumber(12));
  }

  value = subtract(value, employerValue);
  let intervalValue = divide(value, bignumber(interval));
  return currencyFormatterCents.format(
    mathJSFormat(intervalValue, { precision: 13 })
  );
};

export const getFieldValue = (product, field, defaultValue = "") => {
  let value = null;

  value = get(product, field.PropertyChain, defaultValue);

  if (field.Type === "visionFrequency") {
    if (field.frequency) {
      let frequencyValue = get(
        product,
        field.frequency.PropertyChain,
        defaultValue
      );
      if (!frequencyValue) {
        frequencyValue = "12 months";
      }
      value = {
        frequencyValue,
        inputValue: value,
      };
    }
  }

  if (field.PropertyChain === "Details.InNetworkPlanDesign.Coinsurance") {
    value *= 100;
  }
  return value;
};

export const getDisplayValue = (value, field, typeOverride = null) => {
  try {
    let nextValue =
      field.Type === "percent" || field.Type === "dollar" ? value || 0 : value;

    const type = typeOverride === null ? field.Type : typeOverride;
    if (type in displayFormatMap) {
      nextValue = displayFormatMap[type](value, { decimals: 0 });
    }
    if (type === "dollar") {
      nextValue = "$" + nextValue;
    } else if (
      type === "percent" ||
      type === "percentOfEmployeeElectedAmount"
    ) {
      nextValue += "%";
    } else if (typeof value === "object" && "inputValue" in value) {
      nextValue = nextValue.inputValue;
    }
    return nextValue;
  } catch (err) {
    console.warn(err);
    return null;
  }
};

export const getFieldsObject = (sections) => {
  return sections?.reduce((prev, section, sectionIdx) => {
    return section?.Fields?.reduce(
      (p, field) => ({
        ...prev,
        [field?.PropertyChain]: {
          ...field,
          sectionIdx,
        },
      }),
      prev
    );
  }, {});
};
