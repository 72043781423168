import { getAttributes } from "../../brite-viewer/utils";

export const Space = ({
  item,
  totalColumns,
  containerAttributes,
  containerStyle,
}) => {
  const componentAttributes = getAttributes({
    column: item,
    length: totalColumns,
    type: "component",
  });

  const spaceStyle =
    item.component === "space"
      ? { borderRadius: containerAttributes?.style?.borderRadius || "0" }
      : {};

  const componentStyle = { ...componentAttributes.style, ...spaceStyle };

  return (
    <div style={containerStyle}>
      <div {...componentAttributes} style={componentStyle} />
    </div>
  );
};
