import { MenuItem } from "@material-ui/core";
import { css } from "emotion";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Div,
  Modal,
  Select,
  Text,
} from "../../../../shared-components";
import { flex } from "../../../../shared-components/shared-styles";
import { colors } from "../../../../shared-components/styles";
import { useStore } from "../../../store-provider/use-store";
import { CarrierLogo } from "./carrier-logo";
import { getFieldConfigs } from "./configs/config-utils";
import { summaryFieldConfigs } from "./configs/product-summary.config";
import { CostSummary } from "./cost-summary";
import { conditionalFields, nonEditable } from "./product-layout-constants";
import { SummaryField } from "./summary-field";
import { smallScreen } from "../../../decision-tool-views/additional-benefits/product-components/shared-styles";

export const sortMap = {
  core: "a",
  buyup: "b",
  narrow: "c",
};

export const PRODUCT_HEADERS = {
  insurance_plan: "Medical",
  dental_buyup: "Dental Insurance",
  vision_buyup: "Vision",
  dental_ortho_buyup: "Dental + Orthodontics",
  long_term_disability: "Long Term Disability",
  short_term_disability: "Short Term Disability",
  supplimental_life: "Supplemental Life",
  accident_plan: "Accident Insurance",
  hospital_indemnity: "Hospital Indemnity",
  cancer_buyup: "Cancer Insurance",
  critical_illness: "Critical Illness Insurance",
  hsa_buyup: "Health Savings Account",
  fsa_buyup: "Flexible Spending Account",
  supplemental_life_v2: "Supplemental Life",
  basic_life_v2: "Basic Life",
};

const networkMedicalMap = {
  core: "Core Medical",
  buyup: "Buy Up Network",
  narrow: "Narrow Network",
};

const networkMapAll = {
  core: "Core",
  buyup: "Buy Up",
};

export const getNetworkMap = (type) =>
  type === "insurance_plan" ? networkMedicalMap : networkMapAll;

export const ProductComparison = ({
  productId = "",
  productType,
  ...modalProps
}) => {
  const {
    data: { products: data, selectedBenefits },
  } = useStore();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const productsByType = data?.filter(({ Type }) => productType === Type);
    const sortedProducts = productsByType?.sort(
      (a, b) =>
        a?.MultiNetworkID?.localeCompare(b?.MultiNetworkID) ||
        sortMap[a?.MultiNetworkCategory]?.localeCompare(
          sortMap[b?.MultiNetworkCategory]
        )
    );
    setProducts(sortedProducts);
  }, [data]);

  const [selectedComps, setSelectedComps] = useState([]);

  useEffect(() => {
    const id = productId || products?.[0]?.ID;
    const product1 = products?.find(({ ID }) => ID === id);
    const product2 = products?.find(({ ID }) => ID !== id);
    if (product1 && product2) {
      setSelectedComps([product1, product2]);
    }
  }, [products]);

  const costSectionState = useMemo(() => {
    return selectedComps.reduce((prev, item) => {
      const idx = item?.sections?.findIndex(
        ({ DisplayValue }) => DisplayValue === "Premiums & Contributions"
      );
      if (idx > -1) {
        const cost = item?.sections?.[idx];
        const field = cost?.Fields?.find(
          ({ PropertyChain }) => PropertyChain === "Cost"
        );
        return [...prev, field?.State !== "hide"];
      }
      return prev;
    }, []);
  });

  const updateSelectedComps = (event, idx) => {
    let comps = [...selectedComps];
    const product = products?.find(({ ID }) => ID === event.target.value);
    comps.splice(idx, 1, product);
    setSelectedComps(comps);
  };

  const getFieldState = (config, propertyChain) => {
    if (config?.hideField || !propertyChain) {
      return false;
    }

    const hasShowFieldState = selectedComps.some((product) => {
      const field = getField(product?.sections, propertyChain);
      if (!field) {
        return false;
      }
      const condition = conditionalFields?.[propertyChain]?.(product) || "show";
      return field && field.State !== "hide" && condition !== "hide";
    });
    return hasShowFieldState;
  };

  const getAllFields = (sections) =>
    sections?.reduce((prev, item) => [...prev, ...item?.Fields], []);

  const selectedCompIds = selectedComps?.map(({ ID }) => ID);
  const selectableProducts = products?.filter(
    ({ ID }) => !selectedCompIds.includes(ID)
  );

  const compRef = useRef();

  const Comps = ({ children, css: cssString = "", sectionIdx, fieldIdx }) => {
    return (
      <div
        className={css`
          ${flex("left start")}
          margin-left: -32px;
          margin-right: -32px;
          min-width: 300px;
          width: 100%;
          ${cssString}
        `}
      >
        {selectedComps.map((comp, idx) => {
          return (
            <div
              className={css`
                width: 50%;
                max-width: 400px;
                position: relative;
              `}
            >
              {children({
                comp,
                idx,
                propertyChain:
                  selectedComps?.[0]?.sections?.[sectionIdx]?.Fields?.[fieldIdx]
                    ?.PropertyChain,
              })}
            </div>
          );
        })}
      </div>
    );
  };

  const allSections = selectedComps?.[0]?.sections;

  const selectedProduct = selectedComps?.findIndex(({ ID }) =>
    selectedBenefits?.includes(ID)
  );

  const height = compRef?.current?.clientHeight;

  const getField = (sections, propertyChain) => {
    if (!sections) {
      return null;
    }

    for (let i = 0; i < sections.length; i++) {
      const field = sections?.[i].Fields?.find(
        ({ PropertyChain }) => PropertyChain === propertyChain
      );
      if (field) {
        return field;
      }
    }
    return null;
  };

  return (
    <Modal
      {...modalProps}
      full
      onClick={(e) => e.stopPropagation()}
      animate={false}
    >
      <Div
        css={css`
          width: calc(100vw - 64px);
          height: calc(100vh - 64px);
          overflow-y: auto;
          padding: 32px;
          ${smallScreen} {
            padding: 32px 8px;
            width: 100vw;
          }
        `}
      >
        <Div
          css={css`
            ${flex("space-between")} box-sizing: border-box;
          `}
        >
          <Text h1>Compare Plans</Text>
          <Button styles="secondary" onClick={modalProps.onClose}>
            Close
          </Button>
        </Div>

        <div
          className={css`
            ${flex("left")}
          `}
        >
          <div
            className={css`
              position: relative;
              margin-top: 32px;
              padding: 16px 0;
              flex-grow: 1;
            `}
            ref={compRef}
          >
            <Comps>
              {({ idx }) =>
                idx === selectedProduct ? (
                  <div
                    className={css`
                      position: absolute;
                      top: 0;
                      right: calc(${selectedProduct * 32}px + 16px);
                      left: ${selectedProduct * -32}px;
                      border: 4px solid var(--text-body);
                      border-radius: 16px;
                      height: ${height}px;
                    `}
                  >
                    <div
                      className={css`
                        position: absolute;
                        top: -16px;
                        left: calc(50% - 48px);
                        padding: 4px 16px;
                        background-color: var(--text-body);
                        border-radius: 30px;
                      `}
                    >
                      <Text
                        bold
                        css={`
                          color: white;
                        `}
                      >
                        Selected
                      </Text>
                    </div>
                  </div>
                ) : null
              }
            </Comps>

            <Comps
              css={`
                margin: 32px 0;
              `}
            >
              {({ comp }) => (
                <div
                  className={css`
                    border: 1px solid ${colors.gray[300]};
                    border-radius: 8px;
                    ${flex("center")}
                    width: calc(100% - 64px);
                    min-width: 150px;
                  `}
                >
                  {comp?.Details?.IsMultiCarrier ? (
                    <>
                      <CarrierLogo
                        carrierID={
                          comp?.ProviderID ||
                          "00000000-0000-0000-0000-000000000000"
                        }
                        planCarrierName={comp?.ProviderName || ""}
                        maxWidth="150px"
                        maxHeight="100px"
                        position="center"
                      />
                      <div
                        className={css`
                          border-left: 1px solid #26282d;
                          height: 50px;
                        `}
                      ></div>
                      <CarrierLogo
                        carrierID={
                          comp?.Details?.MultiCarrierID ||
                          "00000000-0000-0000-0000-000000000000"
                        }
                        planCarrierName={comp?.Details?.MultiCarrierName || ""}
                        maxWidth="150px"
                        maxHeight="100px"
                        position="center"
                      />
                    </>
                  ) : (
                    <CarrierLogo
                      maxWidth="250px"
                      maxHeight="100px"
                      position="center"
                      carrierID={comp.ProviderID}
                      planCarrierName={comp.ProviderName}
                    />
                  )}
                </div>
              )}
            </Comps>

            <Comps
              css={`
                margin: 0;
              `}
            >
              {({ comp, idx }) =>
                selectableProducts?.length ? (
                  <Select
                    placeholder={
                      comp?.ProductName || PRODUCT_HEADERS[comp?.Type]
                    }
                    defaultValue={comp?.ID}
                    onChange={(event) => updateSelectedComps(event, idx)}
                    css={`
                      margin-bottom: 32px;
                      width: calc(100% - 64px);
                      min-width: 150px;
                    `}
                  >
                    {selectableProducts?.map((product) => (
                      <MenuItem
                        key={"product-list" + product?.ID}
                        value={product?.ID}
                      >
                        <Div
                          css={css`
                            ${flex("space-between")} width: 100%;
                          `}
                        >
                          <Text label>
                            {product?.ProductName ||
                              PRODUCT_HEADERS[product?.Type]}
                          </Text>
                          <Text
                            className={css`
                              flex-grow: 1;
                              text-align: right;
                            `}
                          >
                            {
                              getNetworkMap(product?.Type)[
                                product?.MultiNetworkCategory
                              ]
                            }
                          </Text>
                        </Div>
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  <Text
                    h2
                    css={`
                      margin-bottom: 32px;
                      width: calc(100% - 64px);
                    `}
                  >
                    {comp?.ProductName || PRODUCT_HEADERS[comp?.Type]}
                  </Text>
                )
              }
            </Comps>

            <Comps>
              {({ comp, idx }) =>
                costSectionState.some((item) => item) && (
                  <CostSummary
                    comparisonView={true}
                    markHidden={!costSectionState[idx]}
                    data={{ product: comp }}
                    premiumsSetExternally={comp?.Details?.PlanType?.startsWith(
                      "aca"
                    )}
                  />
                )
              }
            </Comps>

            {allSections?.map((section, sectionIdx) => (
              <>
                <Comps>
                  {({ comp, idx }) => {
                    const section = comp?.sections?.[sectionIdx];

                    return !nonEditable?.sections.includes(
                      section?.DisplayValue
                    ) &&
                      section?.DisplayValue !== "Premiums & Contributions" ? (
                      <div>
                        <Text
                          h4
                          className={css`
                            margin: 32px;
                            margin-bottom: 0;
                            color: var(--text-h4);
                            font-size: 22px;
                          `}
                        >
                          {section?.DisplayValue}
                        </Text>
                      </div>
                    ) : null;
                  }}
                </Comps>
                {section?.Fields?.map((_, fieldIdx) => {
                  return (
                    <Comps sectionIdx={sectionIdx} fieldIdx={fieldIdx}>
                      {({ comp, propertyChain }) => {
                        const field = getField(comp?.sections, propertyChain);
                        const config = summaryFieldConfigs?.[comp?.Type]?.({
                          product: comp,
                        })?.[field?.PropertyChain];
                        return getFieldState(config, field?.PropertyChain) ? (
                          <SummaryField
                            product={comp}
                            field={field}
                            markHidden={true}
                            comparisonView={true}
                            allFields={getAllFields(comp.sections)}
                          />
                        ) : null;
                      }}
                    </Comps>
                  );
                })}
              </>
            ))}
            <Comps>
              {({ comp }) => {
                const allFields = getAllFields(comp.sections);
                return (
                  <>
                    {comp?.Type === "insurance_plan" &&
                      comp?.TitleDescriptions &&
                      comp?.TitleDescriptions.length > 0 && (
                        <Text
                          h4
                          className={css`
                            width: 100%;
                            padding: 16px 32px;
                            padding-top: 32px;
                          `}
                        >
                          Other Details
                        </Text>
                      )}
                    {comp?.TitleDescriptions?.filter(
                      ({ Autogenerated = false }) => !Autogenerated
                    )?.map(({ Title }, idx) => (
                      <SummaryField
                        product={comp}
                        markHidden={true}
                        comparisonView={true}
                        allFields={allFields}
                        field={{
                          DisplayValue: Title,
                          State: "show",
                          Type: "text-input",
                          PropertyChain: `TitleDescriptions[${idx}].Description`,
                        }}
                      />
                    ))}
                  </>
                );
              }}
            </Comps>
          </div>
          <div
            className={css`
              flex-grow: 1;
              height: 100%;
              width: 100%;
              max-width: calc(100vw - ${432 * selectedComps.length}px);
            `}
          />
        </div>
      </Div>
    </Modal>
  );
};
