import React, { useState } from "react";
import { RecommendationList } from "./decision-tool-pages/RecommendationList";
import { Button } from "@material-ui/core";
import { motion, AnimatePresence } from "framer-motion";
import { css } from "emotion";
import { posthogAttrs } from "../../posthog-constants";
import { BriteLanguageSelector } from "../languages";

export const StoredRecommendationViewer = (props) => {
  const [recommendedCustomProducts, setRecommendedCustomProducts] = useState(
    props.recommendedCustomProducts
  );

  return (
    <div
      className={css`
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        background: white;
        overflow: hidden;
        z-index: 1999;
      `}
    >
      <div
        className={css`
          display: flex;
          flex-direction: column;
          height: 100%;
          overflow: hidden;
        `}
      >
        <header
          className={css`
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: ${props.isDesktop ? "8px" : "4px"};
            max-height: 80px;
            border-bottom: 1px solid #e8edf3;
            min-height: ${props.isDesktop ? "65px" : "55px"};
          `}
        >
          <div
            className={css`
              display: flex;
              align-items: center;
              cursor: pointer;
            `}
            onClick={() => {
              props.setCloseClicked((old) => !old);
            }}
          >
            {props.logoUrl && props.isDesktop && (
              <img
                alt="Logo"
                src={props.logoUrl}
                className={css`
                  max-height: 54px;
                `}
              />
            )}

            {props.logoUrl && !props.isDesktop && (
              <img
                alt="Logo"
                src={props.logoUrl}
                className={css`
                  padding-left: 12px;

                  max-height: 32px;
                `}
              />
            )}
          </div>

          <div
            className={css`
              display: flex;
              align-items: center;
              gap: 24px;
              font-family: "Roboto";
              font-style: normal;
              font-weight: 700;
              font-size: 24px;
              line-height: 114%;
              /* identical to box height, or 114% */

              letter-spacing: 0.15px;

              color: #25282d;
              padding-right: 24px;
              padding-left: 24px;
            `}
          >
            <BriteLanguageSelector/> 
            <Button
              style={{
                padding: `${props.isDesktop ? "12px 42px" : "8px 16px"}`,

                borderRadius: "8px",
                minWidth: `${props.isDesktop ? "188px" : "140px"}`,
              }}
              role="nav"
              variant="outlined"
              aria-label="Close"
              className="page-nav-prev"
              onClick={() => {
                props.setCloseClicked((old) => !old);
              }}
              data-ph-capture-attribute-source={
                posthogAttrs?.types?.RECOMMENDATIONS
              }
              data-ph-capture-attribute-action={
                posthogAttrs?.actions?.BACK_TO_GUIDE
              }
            >
              Back to Guide
            </Button>
            {props.isDesktop && (
              <>
                <Button
                  style={{
                    padding: props.isDesktop ? "12px 42px" : "12px 12px",
                    backgroundColor: !props?.page?.EnrollmentURL
                      ? props.designStyles?.Theme?.Body?.ButtonColor
                        ? props.designStyles?.Theme?.Body?.ButtonColor
                        : "#25282D"
                      : "transparent",
                    color: props?.page?.EnrollmentURL
                      ? "#25282D"
                      : "var(--button-text-color)",

                    borderRadius: "8px",
                    minWidth: "188px",
                  }}
                  id="footer"
                  variant={`${
                    props?.page?.EnrollmentURL ? "outlined" : "contained"
                  }`}
                  role="nav"
                  className="page-nav-next"
                  onClick={() => {
                    props.setShareClicked((old) => !old);
                  }}
                  disabled={false}
                  aria-label="See recommendation results"
                >
                  Share Results
                </Button>

                {props?.page?.EnrollmentURL && (
                  <Button
                    style={{
                      padding: props.isDesktop ? "12px 42px" : "12px 12px",
                      backgroundColor: props.designStyles?.Theme?.Body
                        ?.ButtonColor
                        ? props.designStyles?.Theme?.Body?.ButtonColor
                        : "#25282D",
                      color: "#fff",

                      minWidth: "188px",
                    }}
                    id="footer"
                    variant="contained"
                    role="nav"
                    className="page-nav-next"
                    onClick={() => {
                      props.toggleEnrolledClicked();
                    }}
                  >
                    Enroll Now
                  </Button>
                )}
              </>
            )}
          </div>
        </header>
        <main
          className={css`
            flex: auto;
            overflow-y: auto;
            background: #f5f7fa;
          `}
        >
          <motion.div
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            transition={{ ease: "easeOut", duration: 2 }}
            className={css`
              text-align: center;
              width: 100%;
              display: flex;
              flex-direction: column;
            `}
          >
            <section
              className={css`
                button {
                  max-width: 260px;
                }
                width: 100%;
              `}
            >
              <div
                className={css`
                  max-width: 750px;
                  margin: 0 auto;
                  padding: 12px;
                `}
              >
                <AnimatePresence>
                  <RecommendationList
                    setCurrentPageIndex={props.setCurrentPageIndex}
                    oldPageIndex={props.oldPageIndex}
                    {...props}
                    setOnRecommendationListPage={
                      props.setOnRecommendationListPage
                    }
                    recommendedCustomProducts={recommendedCustomProducts}
                    setRecommendedCustomProducts={setRecommendedCustomProducts}
                    shareClicked={props.shareClicked}
                    recId={props.recId}
                    setRecId={props.setRecId}
                    closeClicked={props.closeClicked}
                    setOriginalRecs={props.setOriginalRecs}
                    setUserSelectedRecs={props.setUserSelectedRecs}
                    setDecisionToolOpen={props.setDecisionToolOpen}
                    designStyles={props.designStyles}
                    selectedBenefits={props.selectedBenefits}
                    setSelectedBenefits={props.setSelectedBenefits}
                  />
                </AnimatePresence>
              </div>
            </section>
          </motion.div>
        </main>

        {!props.isDesktop && (
          <div
            className={css`
              height: 80px;
              width: 100%;
              background: white;
              border-top: 1px solid #e8edf3;
              display: flex;
              justify-content: center;
              align-items: center;
              min-height: 80px;
              gap: 12px;
            `}
          >
            <Button
              style={{
                padding: "12px 12px",
                backgroundColor: !props?.page?.EnrollmentURL
                  ? props.designStyles?.Theme?.Body?.ButtonColor
                    ? props.designStyles?.Theme?.Body?.ButtonColor
                    : "#25282D"
                  : "transparent",
                color: props?.page?.EnrollmentURL
                  ? "#25282D"
                  : "var(--button-text-color)",
                marginRight: `${props?.page?.EnrollmentURL ? "0px" : "16px"}`,
                marginLeft: "16px",
                minWidth: "140px",
                width: "100%",
              }}
              id="footer"
              variant={`${
                props?.page?.EnrollmentURL ? "outlined" : "contained"
              }`}
              role="nav"
              className="page-nav-next"
              onClick={() => {
                props.setShareClicked((old) => !old);
              }}
            >
              Share Results
            </Button>

            {props?.page?.EnrollmentURL && (
              <Button
                style={{
                  padding: "12px 12px",
                  backgroundColor: props.designStyles?.Theme?.Body?.ButtonColor
                    ? props.designStyles?.Theme?.Body?.ButtonColor
                    : "#25282D",
                  color: "#fff",
                  marginRight: "16px",
                  marginLeft: "0px",
                  minWidth: "140px",
                  width: "100%",
                }}
                id="footer"
                variant="contained"
                role="nav"
                className="page-nav-next"
                onClick={() => {
                  props.toggleEnrolledClicked();
                }}
              >
                Enroll Now
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
