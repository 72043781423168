import { useHandleAction } from "../../brite-viewer/components/handle-action";
import { getAttributes } from "../../brite-viewer/utils";
import { PDFQRText } from "../PDFQRText";
import { PDFText } from "../PDFText";

export const Button = ({ item, totalColumns, containerStyle }) => {
  const componentAttributes = getAttributes({
    column: item,
    length: totalColumns,
    type: "component",
  });

  const handleAction = useHandleAction(item);

  switch (item?.action?.type) {
    case "open-website":
      return (
        <PDFQRText
          title={item.content}
          description={item?.action?.data?.url}
          url={item?.action?.data?.url}
        />
      );
    case "route-to-page":
      return null;
    case "call-phone":
      return <PDFText text={item?.action?.data?.callNumber} />;
    default:
      return (
        <div style={containerStyle}>
          <button {...componentAttributes} onClick={handleAction}>
            {item.content}
          </button>
        </div>
      );
  }
};
