import { css } from "emotion";
import { Collapse } from "@material-ui/core";
import { flex } from "../../../shared-components/shared-styles";

export const Accordion = ({ item, containerStyle }) => {
  return (
    <div className={containerStyle}>
      <div className={accordionStyle}>
        <div className="title">
          <pre
            className="text-h4-component"
            dangerouslySetInnerHTML={{
              __html: item?.data?.title?.content || "",
            }}
          ></pre>
        </div>
        <Collapse in={true}>
          <div
            className={css`
              margin-left: 40px;
              margin-top: 8px;
            `}
          >
            <pre
              className="text-body-component"
              dangerouslySetInnerHTML={{
                __html: item?.data?.body?.content || "",
              }}
            ></pre>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

const accordionStyle = css`
  padding: 16px;
  margin: 8px;
  width: calc(100% - 48px);
  border-radius: 8px;
  border: 1px solid var(--accent-color-4);
  cursor: pointer;
  :hover {
    outline: 2px solid var(--accent-color-0);
    outline-offset: -2px;
  }
  .title {
    ${flex("left")}
    .svg-container {
      ${flex("center")}
      border-radius: 50%;
      background-color: var(--text-body);
      color: var(--background-color);
      margin-right: 16px;
      min-width: 24px;
      min-height: 24px;
      svg {
        width: 16px;
        height: 16px;
        animation: rotateFoward 0.4s forwards;
      }
    }
  }

  .text-body-component {
    margin-left: 40px;
  }

  .content {
    margin-left: 40px;
    margin-top: 8px;
  }

  pre {
    ol,
    ul,
    p {
      margin: 0;
      li {
        margin: 0;
      }
    }
  }
`;
