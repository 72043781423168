import { css } from "emotion";
import { colors } from "../../../shared-components/styles";
import { getAttributes } from "../../brite-viewer/utils";
import { PDFQRCode } from "../PDFQRCode";

export const Document = ({ item, totalColumns, containerStyle }) => {
  const componentAttributes = getAttributes({
    column: item,
    length: totalColumns,
    type: "component",
  });
  return (
    <div style={containerStyle}>
      <div
        {...componentAttributes}
        style={{ width: "100%", boxSizing: "border-box" }}
      >
        <div
          className={css`
            background-color: ${colors.gray[100]};
            display: flex;
            align-items: center;
            justify-content: start;
            border-radius: 8px;
            width: 100%;
            height: 64px;
          `}
        >
          <PDFQRCode
            hasDefaultContainer={false}
            size={64}
            value={item?.document?.PublicURL}
          />
          <p
            className={css`
              font-size: 18px;
              color: ${colors.black};
              margin-left: 16px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            `}
          >
            {item?.document?.Name}
          </p>
        </div>
      </div>
    </div>
  );
};
