import { css } from "emotion";
import QRCode from "react-qr-code";
import { Div, Text } from "../../../shared-components";

export const formatUrl = (text) => {
  let url = text;
  if (!/^https?:\/\//i.test(url) && !/^http?:\/\//i.test(url)) {
    url = "https://" + url;
  }
  try {
    const data = new URL(url);
    return [data.href, true];
  } catch (err) {
    return ["", false];
  }
};

export const NetworkSearch = ({ data }) => {
  const {
    IsMultiCarrier,
    MultiCarrierNetworkName,
    MultiCarrierNetworkDescription,
    MultiCarrierNetworkCarrierSearchUrl,
    NetworkName,
    NetworkDescription,
    NetworkProviderSearchUrl,
  } = data?.product?.Details || {};

  const {
    ProductNetworkName,
    ProductNetworkDescription,
    ProductNetworkProviderSearchUrl,
  } = data?.product || {};

  return (
    <Div
      css={css`
        padding: 8px;
        width: 100%;
      `}
    >
      <Div
        css={css`
          display: flex;
          padding: 16px 0px;
          align-items: flex-start;
          gap: 24px;
          align-self: stretch;
        `}
      >
        <Text h4>Network Information</Text>
      </Div>
      <Div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 16px;
        `}
      >
        <Div
          css={css`
            display: flex;
            justify-content: space-between;
          `}
        >
          <Div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 16px;
            `}
          >
            <Text h2>
              Search for {ProductNetworkName || NetworkName} Providers
            </Text>
            <Text body>{ProductNetworkDescription || NetworkDescription}</Text>
          </Div>
          <Div
            css={css`
              padding: 8px;
            `}
          >
            <QRCode
              size={108}
              value={
                ProductNetworkProviderSearchUrl ||
                NetworkProviderSearchUrl ||
                ""
              }
            />
          </Div>
        </Div>
        {IsMultiCarrier && (
          <Div
            css={css`
              display: flex;
              justify-content: space-between;
            `}
          >
            <Div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 16px;
              `}
            >
              <Text h2>Search for {MultiCarrierNetworkName} Providers</Text>
              <Text body>{MultiCarrierNetworkDescription}</Text>
              <Div
                css={css`
                  padding: 8px;
                `}
              >
                <QRCode
                  size={108}
                  value={MultiCarrierNetworkCarrierSearchUrl || ""}
                />
              </Div>
            </Div>
          </Div>
        )}
      </Div>
    </Div>
  );
};
