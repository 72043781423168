import React, { useEffect } from "react";

import { DecisionToolViewer } from "./decision-tool-viewer";

import { DecisionToolIntroViewer } from "./decision-tool-pages/DecisionToolIntroViewer";
import { StoredRecommendationViewer } from "./stored-recommendation-viewer";
import { EnrollModal } from "./enroll-modal";
import { useStore } from "../store-provider/use-store";

export const DecisionToolDecider = (props) => {
  const {
    data: { selectedBenefits },
    setStore,
  } = useStore();
  const setSelectedBenefits = (fnValue) => {
    let value = fnValue;
    if (typeof fnValue === "function") {
      value = fnValue(selectedBenefits);
    }
    setStore("selectedBenefits", value);
  };

  const [shareClicked, setShareClicked] = React.useState(false);
  const [closeClicked, setCloseClicked] = React.useState(false);

  const [enrollClicked, setEnrollClicked] = React.useState(false);

  const restart = () => {
    props.clearStoredRecs();
    props.restartRecommendationFunction();
    setSelectedBenefits([]);
  };

  const editResponses = () => {
    props.setUserSelectedRecs(null);
  };

  useEffect(() => {
    if (props?.userSelectedRecs?.selectedBenefits?.length) {
      setSelectedBenefits([...props?.userSelectedRecs?.selectedBenefits]);
    }
  }, [props?.userSelectedRecs?.selectedBenefits]);

  if (!props.decisionTool) {
    return (
      <div className="themeable-content">
        <DecisionToolIntroViewer {...props} />
      </div>
    );
  }

  if (
    props.decisionTool &&
    (props.decisionTool === "aca" || !props.userSelectedRecs)
  ) {
    return (
      <>
        <DecisionToolViewer
          setCurrentPageIndex={props.setCurrentPageIndex}
          oldPageIndex={props.oldPageIndex}
          {...props}
          recId={props.recId}
          setRecId={props.setRecId}
          shareClicked={shareClicked}
          setShareClicked={setShareClicked}
          closeClicked={closeClicked}
          setCloseClicked={setCloseClicked}
          designStyles={props.designStyles}
          isDesktop={props.isDesktop}
          selectedBenefits={selectedBenefits}
          setSelectedBenefits={setSelectedBenefits}
          setUserAnswers={props.setUserAnswers}
          userAnswers={props.userAnswers}
          toggleEnrolledClicked={() => {
            setEnrollClicked((old) => !old);
          }}
        />

        {enrollClicked && (
          <EnrollModal
            setShow={setEnrollClicked}
            url={props?.currentPage?.EnrollmentURL}
            logoURL={props?.currentPage?.EnrollmentLogoURL}
            designStyles={props.designStyles}
            setCloseClicked={setCloseClicked}
          />
        )}
      </>
    );
  }

  if (props.decisionTool === "open" && props.userSelectedRecs) {
    return (
      <>
        <StoredRecommendationViewer
          setCurrentPageIndex={props.setCurrentPageIndex}
          oldPageIndex={props.oldPageIndex}
          {...props}
          {...props.userSelectedRecs}
          restart={restart}
          shareClicked={shareClicked}
          setShareClicked={setShareClicked}
          recId={props.recId}
          setRecId={props.setRecId}
          closeClicked={closeClicked}
          setCloseClicked={setCloseClicked}
          designStyles={props.designStyles}
          isDesktop={props.isDesktop}
          selectedBenefits={selectedBenefits}
          setSelectedBenefits={setSelectedBenefits}
          editResponses={editResponses}
          toggleEnrolledClicked={() => {
            setEnrollClicked((old) => !old);
          }}
        />

        {enrollClicked && (
          <EnrollModal
            setShow={setEnrollClicked}
            url={props?.currentPage?.EnrollmentURL}
            logoURL={props?.currentPage?.EnrollmentLogoURL}
            designStyles={props.designStyles}
            setCloseClicked={setCloseClicked}
          />
        )}
      </>
    );
  }
};
