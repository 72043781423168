import { useEffect } from "react";
import { IconContext } from "phosphor-react";
import { colors } from "../shared-components/styles";
import { StoreProvider } from "./store-provider/provider";
import { ViewerRenderer } from "./ViewerRenderer";
import { useFeatureFlagPayload } from "posthog-js/react";

export const App = () => {
  // Set defaults for all phosphor icons.
  const phosphorTheme = {
    color: colors.black,
    size: 32,
    weight: "regular",
    mirrored: false,
  };

  const languageIntegrationFF = useFeatureFlagPayload("integrated-language-translation");

  useEffect(() => {
    if (!languageIntegrationFF?.value) return;

    // Add script to header
    const weGlotInitScript = document.createElement("script");
    weGlotInitScript.innerText = `Weglot.initialize({
            api_key: 'wg_a2ba8b2e281abc096c1790426f0419d13',
            hide_switcher: true,
        });`;

    document.head.appendChild(weGlotInitScript);

  }, [languageIntegrationFF?.value]);

  return (
    <IconContext.Provider value={phosphorTheme}>
      <StoreProvider>
        <ViewerRenderer />
      </StoreProvider>
    </IconContext.Provider>
  );
};
